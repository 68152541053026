import React, { useRef, useState } from "react";
import style from "./index.module.scss";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useStoreState } from "easy-peasy";
import { Switcher } from "../../components";

const PrizesPage = () => {
  const { t } = useTranslation();
  const ref = useRef();
  const { lang } = useStoreState((store) => store.user);

  const prizes = [
    {
      en: {
        image: "/images/prizes/Prizes_EN.png",
      },
      hu: {
        image: "/images/prizes/Prizes_HU.png",
      },
      sr: {
        image: "/images/prizes/Prizes_RS.png",
      },
    },
  ];

  const filters = [
    {
      value: "prizes",
      label: t("prizes_and_rules.select.prizes"),
    },
    {
      value: "rules",
      label: t("prizes_and_rules.select.rules"),
    },
  ];
  const [filter, setFilter] = useState(filters[0]);

  return (
    <div className={classNames(style.prizes_container, "container")} ref={ref}>
      <Switcher options={filters} selected={filter} onChange={setFilter} />
      {filter.value === filters[0].value ? (
        <div className={classNames(style.prizes)}>
          {prizes.map((item, index) => {
            return (
              <div 
                key={index} 
                className={classNames(style.prize_card)} 
              >
                <img src={item?.[lang]?.image || item?.en?.image} alt={`Prize ${index + 1}`} />
              </div>
            );
          })}
          <div className={style.prize_info}>
            <h2>{t("prizes_and_rules.prizes.title")}</h2>
            <p>{t("prizes_and_rules.prizes.description")}</p>
          </div>
        </div>
      ) : lang === "hu" ? (
        <div className={style.rules}>
          <h2>HOGYAN VEHET RÉSZT:</h2>
          <span className={style.single}>
            <h3>1. LÉPÉS: NYISSA MEG AZ FK TSC ALKALMAZÁST</h3>
            <p>
              Töltse le az FK TSC Alkalmazást okostelefonjára vagy táblagépére, és nyissa meg. <br />
              Navigáljon a menüben található „Tippjáték” szekcióhoz.
            </p>
          </span>
          <span className={style.single}>
            <h3>2. LÉPÉS: KÉSZÍTSE EL NAPI TIPPJEIT</h3>
            <p>
              Látogassa meg az FK TSC Alkalmazást naponta, hogy elkészítse vagy frissítse tippjeit. <br />
              Megtippelheti a győztest és a pontos eredményt minden mérkőzésre. Tippelni a mérkőzés kezdetéig lehet.
            </p>
          </span>
          <span className={style.single}>
            <h3>3. LÉPÉS: SZEREZZEN PONTOKAT VALÓS VALÓSZÍNŰSÉGEK ALAPJÁN</h3>
            <p>
              A pontok valós valószínűségek alapján kerülnek kiosztásra. <br />
              A magas valószínűségű tippek kevesebb pontot érnek, míg a kockázatosabbak többET, így a verseny izgalmas és dinamikus marad.
            </p>
          </span>
          <span className={style.single}>
            <h3>4. LÉPÉS: NYERJEN ÉRTÉKES DÍJAKAT</h3>
            <p>
              Mind az összesített győztes, mind a napi győztesek érkékes nyereményekkel gazdagodhatnak. <br />
              A nyeremények részleteit ellenőrizze az FK TSC Alkalmazás „Nyeremények” szekciójában.
            </p>
          </span>
        </div>
      ) : lang === "sr" ? (
        <div className={style.rules}>
          <h2>PRAVILA ZA UČEŠĆE:</h2>
          <span className={style.single}>
            <h3>KORAK 1: OTVORITE FK TSC APLIKACIJU</h3>
            <p>
              Preuzmite FK TSC Aplikaciju na svom pametnom telefonu ili tabletu i otvorite je. Idite na deo "Predikcije" u meniju.
            </p>
          </span>
          <span className={style.single}>
            <h3>KORAK 2: NAPRAVITE SVOJE DNEVNO PREDVIĐANJE</h3>
            <p>
              Posetite FK TSC Aplikaciju svakodnevno da napravite ili ažurirate svoja predviđanja. Predvidite pobednika i tačan rezultat za svaku utakmicu. Možete napraviti predviđanja do početka utakmice.
            </p>
          </span>
          <span className={style.single}>
            <h3>KORAK 3: OSVOJITE POENE NA OSNOVU PRAVIH VEROVATNOĆA</h3>
            <p>
              Poeni se dodeljuju na osnovu pravih verovatnoća. <br />
              Predviđanja sa visokim verovatnoćama donose manje poena, dok rizičnija predviđanja donose više poena, čineći takmičenje uzbudljivim i dinamičnim.
            </p>
          </span>
          <span className={style.single}>
            <h3>KORAK 4: OSVOJITE ATRAKTIVNE NAGRADE</h3>
            <p>
              I ukupni pobednik i dnevni pobednici imaju šansu da osvoje atraktivne nagrade. 
              Za detalje o nagradama, proverite deo "Nagrade" u svojoj FK TSC Aplikaciji.
            </p>
          </span>
        </div>
      ) : (
        <div className={style.rules}>
          <h2>HOW TO PARTICIPATE:</h2>
          <span className={style.single}>
            <h3>STEP 1: OPEN THE FK TSC APP</h3>
            <p>
              Download the FK TSC App on your smartphone or tablet and open it. <br />
              Navigate to the "Prediction Game" section in the menu.
            </p>
          </span>
          <span className={style.single}>
            <h3>STEP 2: MAKE YOUR DAILY PREDICTION</h3>
            <p>
              Visit the FK TSC App daily to make or update your predictions. <br />
              Predict the winner and the exact score for each match. You can make predictions until the match starts.
            </p>
          </span>
          <span className={style.single}>
            <h3>STEP 3: EARN POINTS BASED ON REAL PROBABILITIES</h3>
            <p>
              Points are awarded based on real probabilities. <br />
              Predictions with high probability earn fewer points, while riskier predictions earn more, keeping the competition exciting and dynamic. 
            </p>
          </span>
          <span className={style.single}>
            <h3>STEP 4: WIN ATTRACTIVE PRIZES</h3>
            <p>
              Both the overall winner and the daily winners have the chance to win attractive prizes. <br />
              For details about the prizes, check the "Prizes" section in your FK TSC App.
            </p>
          </span>
        </div>
      )}
    </div>
  );
};

export default PrizesPage;
