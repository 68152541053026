import React from "react";

import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { useStoreActions, useStoreState } from "easy-peasy";
import classNames from "classnames";

const OddSelector = ({ odd, team_one, team_two, userBets }) => {
  const { t } = useTranslation();
  const { setBets } = useStoreActions((actions) => actions.matches);
  const { bets } = useStoreState((actions) => actions.matches);

  const oddHandler = (item) => {
    const payload = {
      gameId: odd?.gameId,
      oddId: odd?._id,
      oddOptionId: item?._id,
    };
    setBets(payload);
  };

  return (
    <div className={styles.odd_selector}>
      <h2>{t(`games.bets.${odd?.name}`)}</h2>
      <div className={styles.odd_options}>
        {odd?.oddOptions.map((item, index) => {
          return (
            <div className={styles.odd_option} key={index}>
              <button
                className={classNames(styles.logo, {
                  [styles.active]: bets?.[odd?.gameId]?.[odd?._id] === item?._id,
                })}
                onClick={() => oddHandler(item)}
                disabled={!item.value}
              >
                {item.name === team_one?.name ? (
                    team_one?.logo 
                    ? <img src={team_one.logo} alt={item.name} />
                    : <p>{t(item?.name)}</p>                  
                ) : item.name === team_two?.name ? (
                  team_two?.logo 
                  ? <img src={team_two.logo} alt={item.name} />
                  : <p>{t(item?.name)}</p>
                ) : (
                  <p>{t(`games.match_card.${item?.name}`)}</p>
                )}
              </button>
              <p className={styles.value}>
                {item.value && (
                  <>
                    {item.value.toFixed(2)} {t("app.general.pts")}
                  </>
                )}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default OddSelector;
