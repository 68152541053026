import React, { useState } from "react";
import styles from "./index.module.scss";
import closeIcon from "../../assets/icons/close-icon.svg";
import Button from "../Button";
import { useTranslation } from "react-i18next";

const GoalScorerModal = ({ players, selectedPlayer, onSaveSelection, onClose }) => {
  const { t } = useTranslation();
  const [ selection, setSelection ] = useState(selectedPlayer);
  const handleSaveSelection = () => {
    onSaveSelection(selection);
  };

  return (
    <div className={styles.pop_up_prediction_container}>
      <div className={styles.pop_up_container__inner}>
        <div className={styles.btn_close__container}>
          <button
            className={styles.btn_close}
            onClick={onClose}
          >
            <img src={closeIcon} alt="close" />
          </button>
        </div>
        <h3 className={styles.title}>Goalscorer</h3>
        <div className={styles.playerList}>
        <div
          className={`${styles.player} ${selection === null ? styles.selected : ""}`}
          onClick={() => setSelection(null)}
        >
          <p className={styles.playerName}></p>
          <p className={styles.playerPoints}>0.00 pts</p>
        </div>     
        {players?.sort((a,b) => (a.points - b.points))?.map((player) => (
          <div
            key={player._id}
            className={`${styles.player} ${selection?._id === player._id ? styles.selected : ""
              }`}
            onClick={() => setSelection(player)}
          >
            <p className={styles.playerName}>{player.label}</p>
            <p className={styles.playerPoints}>{player.points} pts</p>
          </div>
        ))}
        </div>
      </div>
        <div className={styles.button_container}>
        <Button 
          label={t("games.button.save_selection")}
          size="large"
          variant="primary"
          onClick={handleSaveSelection}
          />
        </div>
    </div>
  );
};

export default GoalScorerModal;
