import React, { useEffect } from "react";

import styles from "./index.module.scss";
import moment from "moment";
import "moment/locale/hu";
import "moment/locale/sr";
import dateIcon from "../../assets/icons/date-icon.svg";
import timeIcon from "../../assets/icons/time-icon.svg";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useStoreState } from "easy-peasy";

const PredictionPopUpHeader = ({
  homeTeam,
  awayTeam,
  date,
  competitionName,
  withBackground,
  status,
}) => {
  const { lang } = useStoreState((store) => store.user);
  useEffect(() => {
    moment.locale(lang);
  }, [lang]);
  const { t } = useTranslation();
  return (
    <div
      className={classNames(styles.prediction_header, {
        [styles.with_background]: withBackground === true,
      })}
    >
      <div className={styles.teams}>
        <div className={styles.inner_container}>
          <div className={styles.team_one}>
            <span className={styles.name}>{t(homeTeam.name)}</span>
            <span className={styles.flag}>
              {homeTeam.logo?.includes("http") && <img src={homeTeam.logo} alt="flag" />}
            </span>
          </div>
          <div className={styles.team_two}>
            <span className={styles.name}>{t(awayTeam.name)}</span>
            <span className={styles.flag}>
              {awayTeam.logo?.includes("http") && <img src={awayTeam.logo} alt="flag" />}
            </span>
          </div>
        </div>
        {status === "In Progress" && (
          <div className={styles.live}>
            <span className={styles.bullet} />
            <p>{t("games.match_card.live")}</p>
          </div>
        )}
      </div>
      <div className={styles.divider}>
        <hr />
      </div>
      <div className={styles.prediction_top}>
        <div className={styles.first_group}>
          <div className={styles.group}>
            <div className={styles.icon}>
              <img src={timeIcon} alt="flag" />
            </div>
            <p className={styles.match_date}>{moment(date).format("HH:mm")}</p>
          </div>
          <div className={styles.group}>
            <div className={styles.icon}>
              <img src={dateIcon} alt="flag" />
            </div>
            <p className={styles.match_date}>{moment(date).format("DD MMM YYYY")}</p>
          </div>
        </div>
        <div className={styles.group}>
          <p className={styles.competition_name}>{competitionName}</p>
        </div>
      </div>

      <div className={styles.divider}>
        <hr />
      </div>
    </div>
  );
};

export default PredictionPopUpHeader;
