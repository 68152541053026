import { useTranslation } from "react-i18next";
import { Loader, MatchCard, Switcher, UserProfile } from "../../components"
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";

import style from "./index.module.scss";

const GamesPage = () => {
    const { t } = useTranslation();

    const { profileImage, nickname, points, rank } = useStoreState((store) => store.user);
    const { getUserStats } = useStoreActions((actions) => actions.user);

    const { chalangeId } = useStoreState((store) => store.game);
    const { getChalangeID } = useStoreActions((actions) => actions.game);

    const { matches, isLoadingMatches } = useStoreState((store) => store.matches);
    const { getPastMatches, getFutureMatches } = useStoreActions(
      (actions) => actions.matches
    );
  
    const [isLoading, setIsLoading] = useState(true);
    
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  const filters = [
    {
      value: "future_games",
      label: t("games.select.future_games"),
    },
    {
      value: "past_games",
      label: t("games.select.past_games"),
    },
  ];
  const [filter, setFilter] = useState(filters[0]);
  
  useEffect(() => {
    const fetchData = async () => {
      if (chalangeId) {
        switch (filter.value) {
          case "future_games": {
            await getFutureMatches(chalangeId);
            break;
          }
          case "past_games": {
            await getPastMatches(chalangeId);
            break;
          }
          default:
            break;
        }
        getUserStats(chalangeId);
      }
    };
    fetchData().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chalangeId, filter]);

  useEffect(() => {
    getChalangeID();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    return isLoading ? (
      <Loader />
    ) : (
      <div className={style.matches}>
          <UserProfile profileImage={profileImage} nickname={nickname} points={points} rank={rank}/>
          <Switcher options={filters} selected={filter} onChange={setFilter} />

        {isLoadingMatches 
          ? <Loader />
          : matches?.length > 0 && !isLoadingMatches ? (
            <ul className={style.matchesList}>
              {matches.map((data, i) => (
                <li key={i} className={style.matchCard}>
                  <MatchCard {...data} filter={filter} />
                </li>
              ))}
            </ul>
          ) : (
            !isLoadingMatches && (
              <div className="no-results">
                <div className="text">
                  <h2>{t("app.general.no_results.title")}</h2>
                  <p>{t("app.general.no_results.text")}</p>
                </div>
              </div>
            )
          )
        }
      </div>
    );
};

export default GamesPage;
