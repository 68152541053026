import React from "react";
import { useTranslation } from "react-i18next";
import style from"./index.module.scss";

const UserProfile = ({ profileImage, nickname, points, rank }) => {
  const { t } = useTranslation();

  return (
    <div className={style.userProfile}>
      <div className={style.data}>
        {
          profileImage && (
            <span className={style.image}>
              <img src={profileImage} alt={nickname} />
            </span>
          )
        }
        <span className={style.nickname}>{nickname}</span>
      </div>
      <div className={style.ranking}>
        <span className={style.points}>
          <span className={style.value}>
            {
              !points ?
              "0" : points
            }
          </span>
          <span>
            {
              t("app.general.pts")
            }
          </span>
        </span>
        {
          !(!rank || rank === 0) ?
          <span className={style.rank}>#{rank}</span> : null
        }
      </div>
    </div>
  );
};

export default UserProfile;
