import React from "react";
import { Outlet } from "react-router-dom";
import style from "./index.module.scss";
import Navigation from "../Navigation";

const Layout = () => {
  
  return (
    <>
      <nav>
        <Navigation />
      </nav>
      <main className={style.container}>
        <Outlet />
      </main>
    </>
  );
};

export default Layout;
