import axios from "axios";
import handleJWTExpired from "./handle-jwt-expired";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
  responseType: "json",
  maxContentLength: Infinity,
  maxBodyLength: Infinity,
});

api.setupApiInterceptors = (getRefreshedToken) => {
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const message = error?.response?.data?.message;

      const status = error?.response?.status;

      if (status === 401 && error.config.url !== ENDPOINTS.AUTH.AUTHENTICATION) {
        const response = await handleJWTExpired(api, error, getRefreshedToken);
        return Promise.resolve(response);
      } else {
        console.error(message);
      }
      return Promise.reject(error);
    }
  );
};

export default api;

// ----------------------------------------------------------------------

export const ENDPOINTS = {
    AUTH: {
        AUTHENTICATION: "/authentication/signin/tsc",
        REFRESH_TOKEN: "/authentication/refresh-token",
        POINTS_AND_RANK: "/challenge-rankings/me",
    },
    GAMES: {
        CHALLENGES: `/challenges/external-official-challenge?externalType=${process.env.REACT_APP_PROJECT_TYPE}`,
        FUTURE_MATCHES: `/challenges/:id/matches/sportworld?type=0&externalType=${process.env.REACT_APP_PROJECT_TYPE}`,
        PAST_MATCHES: `/challenges/:id/matches/sportworld?type=2&externalType=${process.env.REACT_APP_PROJECT_TYPE}`
    },
    ODDS: {
      ODDS: "/odds/:gameId/:challengeId",
      USER_BETS: "/user-bets",
      USER_BETS_UPDATE: "/user-bets/:challengeId/:gameId",
    },
    LEADERBOARDS: {
      SELECT_OPTIONS: `/challenge-rankings/external-championship-intervals?externalType=${process.env.REACT_APP_PROJECT_TYPE}`,
      LEADERBOARDS_USERS: "/challenge-rankings",
    },
    CHAT: {
      CHALLENGE: "/challenges/:challengeId",
      MESSAGES: "/messages/:conversationId",
      CONVERSATIONS: "/conversations/:conversationId",
      PROFANITY: "/settings?name=profanityWords",
    }
  };